import React from 'react';
import { map, sortBy } from 'lodash';
import { routes } from 'routes';
import { getAssetIcon } from 'helpers/icons';
import { allAssetTypeFilter } from 'helpers/filterHelper';
import featureConfigHelper from 'helpers/featureConfigHelper';


export const getTabOptions = (translate) => {
    const featureConfig = new featureConfigHelper();

    return {
        overview: {
            title: translate('ABB.Powertrain.Frontend.ptConditionMonitoringOverviewTab'),
            route: routes.Overview
        },
        conditionIndexes: {
            title: translate('ABB.Powertrain.Frontend.ptConditionMonitoringPowertrainsAssetsOverviewTab'),
            route: routes.PowertrainAssetsOverview
        },
        conditionOverview: {
            title: `${translate('ABB.Powertrain.Frontend.conditionIndicesOverviewOverviewTitle')} (BETA)`,
            route: routes.ConditionOverview,
            hidden: true
        },
        maintenanceOverview: {
            title: translate('ABB.Powertrain.Frontend.ptMaintenanceOverviewTab'),
            route: routes.MaintenanceOverview,
            hidden: !featureConfig.isCBMFeatureEnabled()
        },
        cbm: {
            title: translate('ABB.Powertrain.Frontend.ptConditionMonitoringCBMTab'),
            route: routes.Cbm,
            disabled: true,
            hidden: featureConfig.isCBMFeatureEnabled()
        }
    };
};

export const setOptions = (assets, translate) => {
    const options = [];

    const assetTypes = map(assets, (obj) => obj.componentType.typeName)
        .filter((item, index, arr) => arr.indexOf(item) === index);

    map(assetTypes, (assetType, indx) => {
        const label = translate(`ABB.Powertrain.Frontend.${assetType.toLowerCase()}Label`) || assetType;
        options.push({
            id: indx + 1,
            title: <>{getAssetIcon(assetType, { width: 16, height: 16 })} {label}</>,
            assetType,
            assetTypeLabel: label
        });
    });

    return [allAssetTypeFilter(translate), ...sortBy(options, [option => option?.assetTypeLabel])];
};
