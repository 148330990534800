
import { find, replace } from 'lodash';
import moment from 'moment';

import { translate } from 'helpers/translateHelper';
import { healthStatuses } from 'helpers/constants';
import { setMaxPrecision } from 'helpers/formatters';

import { isPrecisionCorrect } from '../helpers';
import { NOTIFICATION_TYPE_ID } from '../constants';
import isEqual from 'lodash/isEqual';


const getHealthStatusWithId = (healthStatusValues, healthStatusID) => {
    const statuses = healthStatusValues && healthStatusValues.healthStatuses;

    if (statuses && statuses.length) {
        return find(statuses, (status) => { return status.healthStatusID === healthStatusID; });
    }
    return null;
};

const setPrecisionIfNeeded = (value, precision) => {
    return isPrecisionCorrect(value, precision) ? value : setMaxPrecision(value, precision);
};

export const getAlertValue = (healthStatusValues) => {
    const healthStatus = getHealthStatusWithId(healthStatusValues, healthStatuses.HEALTHY);
    return healthStatus ? setPrecisionIfNeeded(healthStatus.valueTo) : null;
};

export const getAlarmValue = (healthStatusValues) => {
    const healthStatus = getHealthStatusWithId(healthStatusValues, healthStatuses.CRITICAL);
    return healthStatus ? setPrecisionIfNeeded(healthStatus.valueFrom) : null;
};

export const getNotificationStatus = (healthStatusValues) => {
    const statuses = healthStatusValues && healthStatusValues.healthStatuses;
    const notificationStatus = {
        alertNotificationEnabled: null,
        alarmNotificationEnabled: null
    };

    if (statuses && statuses.length && statuses.length > 0) {
        const alertStatus = find(statuses, (status) => { return status.healthStatusID === healthStatuses.WEAK; });
        const alarmStatus = find(statuses, (status) => { return status.healthStatusID === healthStatuses.CRITICAL; });
        notificationStatus.alertNotificationEnabled = alertStatus?.enableNotification;
        notificationStatus.alarmNotificationEnabled = alarmStatus?.enableNotification;
    }

    return notificationStatus;
};


export const getMinimumValue = (healthStatusValues) => {
    const healthStatus = getHealthStatusWithId(healthStatusValues, healthStatuses.HEALTHY);
    return healthStatus ? parseFloat(parseFloat(healthStatus.valueFrom).toFixed(2)) : null;
};

export const getMaximumValue = (healthStatusValues) => {
    const healthStatus = getHealthStatusWithId(healthStatusValues, healthStatuses.CRITICAL);
    return healthStatus ? parseFloat(parseFloat(healthStatus.valueTo).toFixed(2)) : null;
};


export const alertValidation = (value, { min, max, alarm }) => {
    value = typeof value === 'string' ? replace(value, ',', '.') : value;
    value = parseFloat(value);

    return min > max ?
        min >= value && value >= alarm : min <= value && value <= alarm;
};

export const alarmValidation = (value, { min, max, alert }) => {
    value = typeof value === 'string' ? replace(value, ',', '.') : value;
    value = parseFloat(value);

    return min > max ?
        max <= value && value <= alert : max >= value && value >= alert;
};

export const isOffsetInvalid = (offsetValue, { max, min }) => {
    const possibleValueRange = max - min;
    offsetValue = parseFloat(offsetValue);

    return offsetValue < -possibleValueRange || offsetValue > possibleValueRange;
};

export const getAutoDetectionIntervals = () => {
    return [{
        label: translate('ABB.Powertrain.Frontend.detectionInterval1'),
        value: 1,
        id: 1
    }, {
        label: translate('ABB.Powertrain.Frontend.detectionInterval2'),
        value: 7,
        id: 2
    }, {
        label: translate('ABB.Powertrain.Frontend.detectionInterval3'),
        value: 30,
        id: 3
    },
    {
        label: translate('ABB.Powertrain.Frontend.detectionInterval4'),
        value: 90,
        id: 4
    }];
};


export const isDeviationTooHigh = (defaultThresholds, thresholds) => {
    const defaultAlertAlarmValues = find(defaultThresholds, (defaultThreshold) => {
        return defaultThreshold.healthStatusID === healthStatuses.WEAK;
    });
    const alertAlarmValues = find(thresholds, (threshold) => {
        return threshold.healthStatusID === healthStatuses.WEAK;
    });

    const alertDifference = defaultAlertAlarmValues.valueFrom * 50 / 100;
    const alarmDifference = defaultAlertAlarmValues.valueTo * 50 / 100;

    return alertAlarmValues.valueFrom < defaultAlertAlarmValues.valueFrom - alertDifference ||
        alertAlarmValues.valueFrom > defaultAlertAlarmValues.valueFrom + alertDifference ||
        alertAlarmValues.valueTo < defaultAlertAlarmValues.valueTo - alarmDifference ||
        alertAlarmValues.valueTo > defaultAlertAlarmValues.valueTo + alarmDifference;
};

export const isAlertHigherThanAlarm = (thresholds) => {
    const healthy = find(thresholds, (threshold) => {
        return threshold.healthStatusID === healthStatuses.HEALTHY;
    });
    const critical = find(thresholds, (threshold) => {
        return threshold.healthStatusID === healthStatuses.CRITICAL;
    });
    const weak = find(thresholds, (threshold) => {
        return threshold.healthStatusID === healthStatuses.WEAK;
    });

    const min = healthy ? healthy.valueFrom : null;
    const max = critical ? critical.valueTo : null;

    if (min < max) {
        return weak.valueFrom > weak.valueTo;
    } else {
        return weak.valueTo < weak.valueFrom;
    }
};

export const convertIntervalToDate = (interval) => {
    const fromUtcDate = moment().utc();
    fromUtcDate.subtract(interval.value, 'days');
    return {
        from: fromUtcDate.toJSON(),
        to: moment().utc().toJSON()
    };
};

export const HealthEmailNotificationsList = [
    {
        id: 0,
        labelKey: 'ABB.Powertrain.Frontend.healthEmailNotificationAllAlarms',
        value: false,
        label: 'Alarm',
        notificationTypeID: NOTIFICATION_TYPE_ID.alarm
    },
    {
        id: 1,
        labelKey: 'ABB.Powertrain.Frontend.healthEmailNotificationAllAlerts',
        value: false,
        label: 'Alert',
        notificationTypeID: NOTIFICATION_TYPE_ID.alert
    }
];

export const isEmailNotificationChanged = (newState, prevState) => {
    return !isEqual(newState, prevState);
};
