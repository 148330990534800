import React from 'react';

import { routes } from 'routes';
import { UserProfile } from './UserProfile';
import { Units } from './Units';
import { NotificationSettings } from './NotificationSettings';
import { IconProfile, IconUnits, IconBell } from 'svgIcons/MotionPortalIcons';

export const getMenuOptions = () => {
    return [
        {
            id: 1,
            component: <UserProfile />,
            icon: <IconProfile />,
            route: routes.UserProfile,
            title: 'ABB.Powertrain.Frontend.userProfileSettingProfile',
            hidden: false
        },
        {
            id: 2,
            component: <Units />,
            icon: <IconUnits />,
            route: routes.UserUnits,
            title: 'ABB.Powertrain.Frontend.userProfileSettingUnits',
            hidden: false
        },
        {
            id: 3,
            component: <NotificationSettings />,
            icon: <IconBell />,
            route: routes.NotificationSettings,
            title: 'ABB.Powertrain.Frontend.userProfileNotificationSettings',
            hidden: true
        }
    ];
};
